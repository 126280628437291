<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" content="添加患者信息"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="AddForm" label-width="100px" :rules="rules" style="width: 40%;">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="AddForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
              <el-row>
                <el-col :span="15">
                  <el-input v-model="AddForm.age" placeholder="年龄"></el-input>
                </el-col>
                <el-col :span="9">
                  <el-select v-model="AddForm.type" placeholder="请选择">
                    <el-option label="岁" value="1"></el-option>
                    <el-option label="月" value="2"></el-option>
                    <el-option label="天" value="3"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="AddForm.sex">
                <el-radio :label="true">男</el-radio>
                <el-radio :label="false">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="身份证号" prop="idNo">
              <el-row>
                <el-col :span="14"><el-input v-model="AddForm.idNo" placeholder="身份证号"></el-input></el-col>
                <el-col :span="9" offset="1">
                  <el-button type="primary" size="small" @click="">扫描身份证</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input v-model="AddForm.phone" placeholder="电话号码"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Patient } from "@/components/HospitalDomain/Patient/Patient"
export default {
  data () {
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      patientDomain: patient,
      AddForm: {
        organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
        PatientType: 2,
        address: '',
        age: '',
        name: '',
        phone: '',
        regionId: null,
        sex: true,
        idNo: '',
        fullName: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入患者姓名', trigger: 'blur' },
        ],
        age: [
          { pattern: /^[1-9]\d*$/, message: "请输入大于0，小于10000的正整数", trigger: "blur" },
          { required: true, message: '请输入患者年龄', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入患者联系方式', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
        ],
        idNo: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确格式的身份证号", trigger: "blur" },
        ],
      }
    }
  },
  mounted () { },
  methods: {
    saveData (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.patientDomain.patientAdd(_this.AddForm,
            function (data) {
              _this.$router.go(-1)
            },
            function (error) {
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    ::v-deep .el-table__row>td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }
  }
}

.drawer_body {
  padding: 0 20px 20px;
}

.searchbox {
  position: relative;
}

.category_box {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

.category_box .el-button {
  margin: 5px;
}

.category_box .el-button.active {
  color: #fff;
  background-color: var(--baseColor);
  border-color: var(--baseColor);
}

.tab /deep/ .el-form-item__content {
  height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
  top: 40px;
}

.elSelect /deep/ .el-select-dropdown__item span {
  max-width: 170px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
